<template>
  <div class="source-share">
    <dd-share class="social-share" :share-config="config" v-if="!isWeiXin1 && isReSetConf"></dd-share>
  </div>
</template>

<script>
import Mshare from 'm-share'
import $ from 'jquery'
export default {
  props: ['img', 'desc', 'value'],
  data() {
    let that = this
    return {
      isshow: false,
      isReSetConf: true,
      isWeiXin1: false,
      config: {
        //url: "", // 网址，默认使用 window.location.href
       // source: "", // 来源（QQ空间会用到）, 默认读取head标签：<meta name="site" content="https://github.com/dongdong-cloud/dd-share" />
        //title: "", // 标题，默认读取 document.title 或者 <meta name="title" content="vue一键分享组件" />
       // description: "", // 描述, 默认读取head标签：<meta name="description" content="基于share.js封装的vue组件，一键分享到微博、QQ空间、QQ好友、微信、腾讯微博、豆瓣、Facebook、Twitter、Linkedin、Google+、点点等社交网站。" />
       // image: "", // 图片, 默认取网页中第一个img标签
        sites : ['wechat', 'weibo','facebook'],
       // disabled: ["google", "twitter", 'qq','qzone','linkedin', 'google'], // 禁用的站点 可用站点['weibo', 'qq', 'wechat', 'douban', 'qzone', 'linkedin', 'facebook', 'twitter', 'google']
        wechatQrcodeTitle: "微信扫一扫：分享", // 微信二维码提示文字
        wechatQrcodeHelper: ''
        // wechatQrcodeHelper:
        //   "<p>微信里点“发现”，扫一扫</p><p>二维码便可将本文分享至朋友圈。</p>"
      }
    };
  },
  watch: {
    value(now, old) {
      this.isshow = now
    },
    isshow(now, old) {
      this.$emit('input', now)
    }
  },
  created() {},
  mounted() {
      this.render()
      const cof = {...this.config}
      this.isReSetConf = false
      this.$nextTick( () => {
          
          cof.url = window.location.href
          this.config = cof
          this.isReSetConf = true
      })
      
  },
  beforeDestroy() {
  },
  methods: {
    isWeiXin(){
        var ua = window.navigator.userAgent.toLowerCase();
        if(ua.match(/MicroMessenger/i) == 'micromessenger'){
            return true;
        }else{
            return false;
        }
    },
    render () {
        if (window.innerWidth < 769) {
            if (this.isWeiXin()) {
              this.isWeiXin1 = true
              $('.fenxBox').off().on('click', function () {
                  Mshare.to('wx', {
                        fnDoShare: function (type) {
                            console.log(1);
                        }
                    });
              })
            } else {
                $('.social-share-icon').off().on('click', function () {
                $('.wechat-qrcode').remove()
                    Mshare.to('wx', {
                        fnDoShare: function (type) {
                            console.log(1);
                        }
                    });
             });
            }
            
        }
    }
  },
};
</script>

<style lang="scss">
.source-share {
  position: absolute;
    top: 38px;
}
.social-share {display: none;}
.social-share {
    flex-direction: column;
    margin-top: 10px;
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
}
@media (max-width: 768px) {
    
}
</style>
